<template>
  <div class="join-page">
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container has-text-centered">
          <h1 class="title is-size-2">Join the PFDAA</h1>
        </div>
      </div>
    </div>

    <div class="container">
      <p class="org-description is-size-6">
        Membership in the PFDAA offers a variety of benefits, including week long summer dance festivals, access to our music and video library, and opportunities to work with choreographers from Poland during workshops.
Past festivals have offered our members a variety of festival sites including Detroit, Philadelphia, Chicago, Baltimore, San Francisco, Boston, Minneapolis, New York, and Montreal. These festivals provide an excellent environment for meeting new people, learning new repertoires from well known instructors, and the opportunity to renew and refine your ensemble’s repertoire.
Other membership benefits include discount registration fees for festivals and workshops, and an expansive audio and video library from many regions of Poland to be used for research or for your own viewing and listening pleasure. In 1999, 2005, and 2011, the PFDAA organized a performing tour of Poland prior to the Rzeszów Festival.
As an association, we are always striving to bring forth new ideas to help gain knowledge of Polish folk dance, provide participating ensembles an avenue for networking, and increase our member groups' exposure. The PFDAA newsletter and website keep member groups abreast of association activities and is an excellent means of sharing your group’s activities with the association's members.
Membership in the association can be a rewarding social experience and educational tool for your organization. Your initial membership fee of $125.00 USD helps support processing your application, technology fees, and music acquisition. Annual dues are $35.00 USD thereafter. If you have any more questions, please feel free to email us at
        <a href="mailto: pfdaacontact@gmail.com">pfdaacontact@gmail.com</a>.
      </p>

      <div class="buttons has-text-centered">
        <a href="https://firebasestorage.googleapis.com/v0/b/pfdaa-7f092.appspot.com/o/PFDAA%20Welcome%20Letter%20%26%20Application-3-1.pdf?alt=media&token=783270d4-efa0-4641-885c-4bd47309d5b6" download="welcomeLetterAndApplication.pdf">
          <button class="welcome button is-primary">Welcome Letter and Application</button>
        </a>
      </div>

      <div id="map" class="map-container"></div> <!-- Leaflet map container -->
    </div>
  </div>
</template>

<script>
import L from "leaflet"; // Import Leaflet library
import "leaflet/dist/leaflet.css";
import axios from "axios";
import EnsembleService from "../services/ensembleService";

export default {
  name: "Join",
  data() {
    return {
      markers: [],
      map: null,
    };
  },

  async mounted() {
    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
      iconUrl: require('leaflet/dist/images/marker-icon.png'),
      shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    });

    // Fetch ensemble data and initialize the map
    await this.fetchMarkers();
    this.initMap();
  },
  
  methods: {
    initMap() {
      // Initialize the Leaflet map
      this.map = L.map("map").setView([40.306106, -97.942105], 4);

      // Load and display tile layer from OpenStreetMap
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(this.map);

      this.addMarkers();
    },

    async fetchMarkers() {
      let ensembles = await EnsembleService.getAll();

      for (const ensemble of ensembles) {
        let marker = await this.getMarkerData(ensemble);
        if (marker) {
          this.markers.push(marker);
        }
      }
    },

    async getMarkerData(ensemble) {
      if (ensemble.latitude && ensemble.longitude) {
        return {
          position: { lat: ensemble.latitude, lng: ensemble.longitude },
          name: ensemble.name
        };
      }

      const location = await this.fetchCoordinates(ensemble);
      if (location) {
        await EnsembleService.update(ensemble.id, { latitude: location.lat, longitude: location.lng });
        return {
          position: { lat: location.lat, lng: location.lng },
          name: ensemble.name
        };
      }
      return null;
    },

    async fetchCoordinates(ensemble) {
      const query = ensemble.zip
        ? `postalcode=${ensemble.zip}`
        : `city=${ensemble.city}&state=${ensemble.state}`;
      
      const url = `https://nominatim.openstreetmap.org/search?format=json&${query}&limit=1`;

      try {
        const response = await axios.get(url);
        if (response.data.length > 0) {
          const location = response.data[0];
          return {
            lat: parseFloat(location.lat),
            lng: parseFloat(location.lon)
          };
        } else {
          console.error("No results found for:", query);
          return null;
        }
      } catch (error) {
        console.error("Error fetching coordinates from Nominatim:", error);
        return null;
      }
    },

    addMarkers() {
      this.markers.forEach((markerData) => {
        // Create and add a marker for each location
        L.marker([markerData.position.lat, markerData.position.lng])
          .addTo(this.map)
          .bindPopup(`<b>${markerData.name}</b>`)
          .openPopup();
      });
    },
  },
};
</script>

<style scoped>
.org-description {
  margin-top: 50px;
  line-height: 1.6;
  padding: 0 10px;
}

.map-container {
  margin: 20px 0;
  width: 100%;
  height: 400px; /* Set height for the map */
}

.hero-body {
  background: linear-gradient(to right, #93291e, #ed213a);
}

.welcome {
  margin-bottom: 20px;
  font-size: 1rem;
  padding: 0.75rem 1.5rem;
  transition: 0.3s;

  &:hover {
    background-color: #3e8e41;
    color: white;
  }
}

.buttons {
  text-align: center;
  margin-top: 20px;
}

@media screen and (max-width: 768px) {
  .org-description {
    font-size: 0.875rem;
    margin: 20px 0;
    padding: 0 15px;
  }

  .map-container {
    height: 300px;
  }

  .welcome {
    width: 100%;
    padding: 1rem;
  }
}
</style>
